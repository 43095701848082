'use client'
import React from 'react'
import { Title } from '../components/fragments/Title'
import { Text } from '../components/fragments/Text'
import { CopyToClipBoardButton } from '../components/fragments/CopyToClipBoardButton'
import { Card } from '../components/fragments/Card'
import { AnchorButton } from '../components/fragments/AnchorButton'
import Logo from '../assets/svg/Logo.svg'
import { ReactSVG } from 'react-svg'
import { useParams } from 'react-router-dom'
import { Buffer } from 'buffer'

const HomePage = () => {
  const { invitationCode } = useParams()

  const decodedInvitationCode = React.useMemo(() => Buffer.from(invitationCode || '', 'base64').toString('ascii'), [invitationCode])

  return (
    <div className='flex flex-col mx-auto w-fit'>
      <h1 className='self-start mb-[32px]'>
        <a
          href='https://www.zummafinancial.com/es'
          target='_blank'
          rel='noreferrer'
        >
          <ReactSVG src={Logo} />
        </a>
      </h1>

      <Title className='mb-[16px]'>Un@ de tus amig@s quiere verte feliz con tus finanzas 😉</Title>

      <Text className='leading-[30px] mb-[16px]'>
        Tu amig@ te quiere regalar <b className='text-tertiary-1 font-semibold'>50% de descuento</b> en tu primer mes de Zumma. <b className='text-tertiary-1 font-semibold'>¡En todos los Planes!</b>
      </Text>

      <Text className='mb-[16px]'>1. Copia el código que tu amig@ te regaló ⬇️</Text>

      <Card className='sm:self-center mb-[16px]'>
        <b className='mb-[16px] text-text-1 font-semibold'>Tu código es:</b>
        <div className='flex items-center gap-2.4'>
          <b className='text-tertiary-1 font-bold'>{decodedInvitationCode}</b>
          <CopyToClipBoardButton value={decodedInvitationCode} />
        </div>
      </Card>

      <Text className='mb-[16px]'>2. Descarga la app de Zumma en iOS o Android</Text>

      <AnchorButton
        href='https://apps.apple.com/mx/app/zumma-financial/id1668855235'
        className='sm:self-center mb-[16px]'
      >
        Descarga la app para iOS
      </AnchorButton>

      <AnchorButton
        href='https://play.google.com/store/apps/details?id=com.zummafinancial'
        className='sm:self-center mb-[16px]'
      >
        Descarga la app para Android
      </AnchorButton>

      <Text className='mb-[16px]'>3. Al registrarte pega el código que tu amigo te regaló, el cual copiaste en el paso 1.</Text>

      <Text className='mb-[16px]'>4. Indica en el cuestionario si vienes a Zumma por el producto de facturación o por el producto de gestión de gastos.</Text>

      <Text className='mb-[16px]'>5. Elige tu suscripción y utiliza alguno de estos dos códigos al pagar dependiendo del plan que quieres:</Text>

      <div className='pl-[18px]'>
        <div className='mb-[12px] font-inter'>
          Plan Premium: <b className='text-tertiary-1 font-bold'>50PP2024</b> <CopyToClipBoardButton value={'50PP2024'} />
        </div>

        <div className='mb-[12px] font-inter'>
          Plan de Facturación: <b className='text-tertiary-1 font-bold'>50PF2024</b> <CopyToClipBoardButton value={'50PF2024'} />
        </div>
      </div>
      <Text className='mb-[16px]'>6. ¡Y listo! Ya tienes tu descuento.</Text>
    </div>
  )
}

export { HomePage }
